<template>
    <div>
      <Pane />
      <a-card class="container">
        <a-form
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
          :colon="false"
          :form="form"
        >
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="台账单号">
                {{ code }}
              </a-form-item>
            </a-col>
  
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="费用性质">
                <a-select @change="setUnit" v-model="feeNatureCode" placeholder="请选择" style="width: 200px">
                  <a-select-option
                    v-for="item in feeNature"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="编制单位">
                <a-select v-model="unitCode" placeholder="请选择" style="width: 200px">
                  <a-select-option
                    v-for="item in unitList"
                    :key="item.unitCode"
                    :value="item.unitCode"
                  >{{ item.unitName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="归属部门">
                {{ user?.employeeVo?.deptName }}
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="填报人">
                {{ user?.userName }}
              </a-form-item>
            </a-col>
  
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item
                label="费用使用项清单"
                :label-col="{ span: 3 }"
                :wrapper-col="{ span: 19 }"
              >
                <Subject :list="list" />
              </a-form-item>
            </a-col>

          </a-row>
  
          <div class="center">
            <a-space>
              <a-button @click="$close($route.path)">关闭</a-button>
              <a-button type="primary" :loading="loading" @click="process"
                >保存并发起</a-button
              >
              <a-button type="primary" :loading="loading" @click="handleSubmit"
                >保存</a-button
              >
            </a-space>
          </div>
        </a-form>
      </a-card>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import request from "@/api/request";
  import Subject from "./components/subject.vue";
  import organization from "@/mixins/organization";
  function queryUnitList(params) {
    return request({
      url: "/office-service/quality/fee/use/queryUnitList",
      params
    });
  }
  function getCode() {
    return request({
      url: "/office-service/quality/fee/use/getCode",
    });
  }
  function save(data) {
    return request({
      url: "/office-service/quality/fee/use/save",
      method: "post",
      data
    });
  }
  export default {
    name: "technicalQualityScoreAdd",
    mixins: [organization],
    components: {
      Subject,
    },
    computed: {
      ...mapGetters("setting", ["findDataDict"]),
      feeNature() {
        return this.findDataDict("qualitySafety.feeNature");
      },
    },
    data() {
      return {
        feeNatureCode: '',
        code: '',
        form: this.$form.createForm(this),
        user: '',
        list: [],
        users: [],
        unitList: [],
        loading: false,
        unitCode: ''
      };
    },
    mounted() {
      this.user = JSON.parse(localStorage.getItem("user"));
      getCode().then(res => {
        console.log(res);
        this.code = res
      });
    },
    methods: {
      hanldeSave(isRaise) {
        if (!this.feeNatureCode) {
          this.$message.error("请选择费用性质");
          return;
        }
        if (!this.unitCode) {
          this.$message.error("请选择编制单位");
          return;
        }
        if (!this.list.length) {
          this.$message.error("请填写费用项清单");
          return;
        }

        this.loading = true;
        save({
          code: this.code,
          creatorId: this.user.uuid,
          creatorName: this.user.userName,
          deptId: this.user.employeeVo.deptId,
          deptName: this.user.employeeVo.deptName,
          unitCode: this.unitCode,
          unitName: this.unitList.filter(v=>v.unitCode == this.unitCode)[0].unitName,
          feeNatureCode: this.feeNatureCode,
          isRaise: isRaise,
          itemList: this.list
        })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.loading = false;
        });
      },
      process(e) {
        e.preventDefault();
        this.hanldeSave(true);
      },
      setUnit(value) {
        queryUnitList({feeNatureCode:value}).then(res => {
          this.unitList = res
        });
      },
      handleSubmit(e) {
        e.preventDefault();
        this.hanldeSave(false);
      }
    },
  };
  </script>
  
  
  <style lang="less" scoped>
  .container {
    padding: 12px;
  }
  
  .center {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  </style>